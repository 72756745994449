const urlParts = new URL(window.location.href).hostname.split(".")

import logoValeParana from "../src/assets/logo-vale-parana.png";
import logoVivaSorte from "../src/assets/logo-viva-sorte-branco.png";

const currentDomain = urlParts
    .slice(0)
    .slice(-(urlParts.length === 4 ? 3 : 2))
    .join(".");

/**
 * A list containing all configurations by domain name.
 */
const ConfigurationList = [
    {
        id: "viva-sorte",
        domain: "vivasorteoficial.com.br",
        title: "Viva Sorte",
        apiDomain: "api.vivasorteoficial.com.br",
        logoPath: logoVivaSorte,
        whitelabel: {
            labels: {
                mainRaffles: "Viva Sorte",
                secondaryRaffles: "Hora do Viva"
            }
        }
    },
    {
        id: "homolog",
        domain: "luckymaker-homolog.net.br",
        title: "LuckyMaker Homolog",
        apiDomain: "api.luckymaker-homolog.net.br",
        logoPath: logoVivaSorte,
        whitelabel: {
            labels: {
                mainRaffles: "Principais",
                secondaryRaffles: "Secundários"
            }
        }
    },
    {
        id: "vale-parana",
        domain: "valeparana.com.br",
        title: "Vale Paraná",
        apiDomain: "api.valeparana.com.br",
        logoPath: logoValeParana,
        whitelabel: {
            labels: {
                mainRaffles: "Vale Sorte",
                secondaryRaffles: "Vale Paraná"
            }
        }
    }
];

// Find the current configuration by the current domain name
const currentConfiguration = ConfigurationList.find((config) => config.domain === currentDomain);

// If a configuration has been found, setup it
if (currentConfiguration) {
    document.title = currentConfiguration.title + " Admin";
} else {
    document.title = "Desenvolvimento";
}

const isDev = process.env.NODE_ENV !== "production";

export const Configuration = {
    domain: "local.luckymaker.arpa:3000",

    ...currentConfiguration,
    title: currentConfiguration?.title,
    isDev,
    logo: currentConfiguration?.logoPath,
    settings: {
        // @TODO: Instead of being static, may come from another information source.
        bondDigits: 7
    },
    baseUrl:
    (
        !isDev ?
        `https://${currentConfiguration?.apiDomain}/` :
        "http://localhost:8000/"
    ) + "v1/admin"
};
