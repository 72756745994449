import { Datagrid, DateField, FunctionField, List, ReferenceField, TextField } from "react-admin";

export const ManagedBatchesList = () => (
    <List>
        <Datagrid rowClick="show">
            <FunctionField source="fromBatch" label="Lotes" render={
                (record) => <>
                    <span>{record.fromBatch} &mdash; {record.toBatch}</span>
                    <span className="ml-2 text-xs opacity-80">({record.batchesCount} lotes)</span>
                </>
            } />
            <FunctionField source="occupancyRatio" sortable={false} render={(val) => (Number(val.occupancyRatio) * 100).toFixed(0) + "%"} />
            <DateField source="expiresAt" showTime={true} />
        </Datagrid>
    </List>
);
