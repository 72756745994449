import { CreateButton, CreateButtonProps } from "react-admin";

export const CreateButtonWithDefaults = (props: { resource: string, defaults: object } & CreateButtonProps) => (
    <CreateButton
        {...props}
        to={{
            pathname: `/${ props.resource }/create`,
            search: (
                props.defaults
                    ? `?source=${JSON.stringify(props.defaults)}`
                    : props.defaults
            )
        }}
    />
);
