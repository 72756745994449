import { useEffect, useMemo, useRef } from "react";
import { IntermediateRafflePrize, IntermediateRaffleResult } from "../ResultGeneratorPage";
import { useResultGeneratorConfig } from "./ResultGeneratorContext";
import { IntermediateRaffleResultPartProps, ResultGeneratorPart } from "./ResultGeneratorPart";
import { getPrizeKindInfo } from "./ResultGeneratorHelpers";

export interface ResultGeneratorProps {
    result: IntermediateRaffleResult;
}

/**
 * Recalculates the SVG font size.
 * @param container The container.
 * @param multiplier The multiplier.
 * @returns
 */
function recalculateFontSize(container: HTMLElement, multiplier: number) {
    const children = [...container.querySelectorAll(".scalable-font-size")] as HTMLElement[];

    for (const child of children) {
        const originalSize = child.getAttribute("data-original-size") as string;
        const originalParentWidth = child.getAttribute("data-original-parent-size") as string;

        const calculatedSize = parseFloat(originalSize) * (child.parentElement!.offsetWidth / Number(originalParentWidth));

        child.setAttribute("style", "font-size: " + calculatedSize + "px");
        child.style.setProperty("fontSize", calculatedSize + "px");

        child.parentElement!.setAttribute("style", "line-height: initial !important");

        console.debug("has recalculated font size to %d with multiplier %f", calculatedSize, multiplier);
    }
}

/**
 * Represents the result generator.
 * @param props Any props to be passed to it.
 * @returns
 */
export const ResultGenerator = (props: ResultGeneratorProps) => {
    const containerRef = useRef(null);

    const config = useResultGeneratorConfig();

    function isKindGroupable(kind: IntermediateRafflePrize["raffleKind"]): boolean {
        // NOTE:
        // We MUST show to our users the drawn balls of the prizes (if any),
        // so we avoid grouping prizes of kind MATRIXES.

        // About the condition: If it's not EXPLICITLY true.
        return getPrizeKindInfo(kind)?.hasMatrix !== true;
    }

    /**
     * The parts that will be rendered.
     */
    const parts = useMemo(() => {
        const pages: IntermediateRaffleResultPartProps[] = [];

        let groupedPrizesAndWinners = props.result.prizes;

        // If wants to group by prize title
        if (config.groupByTitle) {
            // Group them
            groupedPrizesAndWinners = [
                ...Object.values(
                    groupedPrizesAndWinners
                        .filter((p) => isKindGroupable(p.raffleKind))
                        .reduce((a, p) => {
                            const titleHash = p.name.trim().toLocaleLowerCase();

                            // Create the prize if it doesn't exists yet
                            a[titleHash] ??= {
                                ...p,
                                winners: []
                            };

                            // Push the current winners into it
                            a[titleHash].winners.push(...p.winners);

                            return a;
                        }, {} as Record<string, IntermediateRafflePrize>)
                ),

                ...groupedPrizesAndWinners.filter((p) => !isKindGroupable(p.raffleKind))
            ];
        }

        // Iterate over all result prizes
        for (const prizeIndex in groupedPrizesAndWinners) {
            const prize = groupedPrizesAndWinners[prizeIndex];
            const maxResults = Math.ceil(prize.winners.length / config.winnersPerImage);

            for (let currentPage = 0; currentPage < maxResults; currentPage++) {
                pages.push({
                    drawDate: config.date as string,
                    pageNumber: currentPage,

                    prize: {
                        ...prize,

                        // If the prize already has an order, uses it. Otherwise, uses autofill.
                        order: prize.order ?? (Number(prizeIndex) + 1),

                        winners: prize.winners.slice(
                            currentPage * config.winnersPerImage,
                            (currentPage + 1) * config.winnersPerImage
                        )
                    }
                });
            }
        }

        return pages;
    }, [
        props.result,
        config.title, config.subtitle, config.colors, config.date, config.groupByTitle
    ]);

    // Run when we get a ref for the container
    useEffect(() => {
        // Recalculate font size
        recalculateFontSize(containerRef.current as any, 1);

        // Iterate over all pages
        for (const page of document.querySelectorAll("div.single-page") as any as HTMLDivElement[]) {
            // Set its original width
            page.setAttribute("data-original-width", page.offsetWidth + "px");
        }
    }, [containerRef]);

    return (
        <div
            ref={containerRef}
            className="flex flex-wrap justify-between w-full gap-3 mt-10 cursor-default"
        >
            {
                parts.map((part, pageIndex) => (
                    <ResultGeneratorPart
                        key={"page-" + pageIndex}
                        {...props}
                        {...part}
                        pageNumber={pageIndex + 1}
                    />
                ))
            }
        </div>
    );
};
