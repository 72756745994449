import { useRecordContext } from 'react-admin';

export const RecordConsumer = ({ children }) => {
    const record = useRecordContext();

    if (!record) {
        return null;
    }

    return children(record);
};
