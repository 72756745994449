import { useDashboardContext } from "../Dashboard";

const MetricElement = (props: {
    icon: string;
    title: string;
    subtitle?: string;
    value: string;
    link?: string;
}) => (
    <div className="p-5">
        <div className="text-neutral-700">
            <i className={props.icon}></i>
            <span className="ml-2">{props.title}</span>

            <p className="mb-0 text-xs text-neutral-500">{props.subtitle}</p>
        </div>

        <h2 className="my-0 mt-3 text-2xl font-bold">
            {!props.link ? props.value : <a className="underline text-primary" href={props.link}>{props.value}</a>}
        </h2>
    </div>
);

const MetricsContainer = (props: {
    className?: string;
    children: any[];
}) => (
    <div
        className={[
            "grid grid-cols-1 gap-3 mb-8 bg-white shadow-md lg:grid-cols-3 xl:grid-cols-6 divide-solid rounded-md",
            props.className
        ].join(" ")}
    >
        {props.children}
    </div>
);

export const MetricsTab = (props: {
    metrics: Record<string, any> | null;
}) => {
    const { metrics } = props;

    const dashboard = useDashboardContext();

    return (
        <div>
            <MetricsContainer>
                <MetricElement
                    title="Total"
                    subtitle="Valor bruto de entrada"
                    icon="fa fa-fw fa-money-bill"
                    value={dashboard.isLoading ? "..." : metrics?.grossEntry}
                />

                <MetricElement
                    title="Total líquido"
                    subtitle="Valor líquido de entrada"
                    icon="fa fa-fw fa-money-bill-trend-up"
                    value={dashboard.isLoading ? "..." : metrics?.netEntry}
                />

                <MetricElement
                    title="Cartão de crédito"
                    subtitle="Valor líquido de entrada"
                    icon="fa fa-fw fa-credit-card"
                    value={dashboard.isLoading ? "..." : metrics?.methods?.creditCard}
                />

                <MetricElement
                    title="PIX"
                    subtitle="Valor líquido no PIX"
                    icon="fab fa-fw fa-pix"
                    value={dashboard.isLoading ? "..." : metrics?.methods?.pix}
                />

                <MetricElement
                    title="PicPay"
                    subtitle="Valor líquido no PicPay"
                    icon="fa fa-fw fa-star"
                    value={dashboard.isLoading ? "..." : metrics?.methods?.picpay}
                />

                <MetricElement
                    title="Vendas físicas"
                    subtitle="Valor bruto de entrada"
                    icon="fa fa-fw fa-hand-holding-usd"
                    value={dashboard.isLoading ? "..." : metrics?.sources?.PHYSICAL?.income ?? "R$ 0,00"}
                />

                <MetricElement
                    title="Total de usuários"
                    subtitle="Total de usuários cadastrados (ignorando todos os filtros)"
                    icon="fa fa-fw fa-users"
                    value={dashboard.isLoading ? "..." : metrics?.general?.registeredUsers}
                />
            </MetricsContainer>

            <div className="grid grid-cols-2 gap-3">
                <MetricsContainer className="!grid-cols-2">
                    <MetricElement
                        title="Vendas na web"
                        subtitle="Títulos vendidos"
                        icon="fa fa-fw fa-laptop"
                        value={dashboard.isLoading ? "..." : metrics?.luckyNumbers?.website ?? 0}
                    />

                    <MetricElement
                        title="Vendas no aplicativo"
                        subtitle="Títulos vendidos"
                        icon="fa fa-fw fa-mobile"
                        value={dashboard.isLoading ? "..." : metrics?.luckyNumbers?.app ?? 0}
                    />

                    <MetricElement
                        title="Títulos físicos"
                        subtitle="Títulos vendidos"
                        icon="fa fa-fw fa-hand-holding-usd"
                        value={dashboard.isLoading ? "..." : metrics?.luckyNumbers?.physical ?? 0}
                    />
                </MetricsContainer>

                <MetricsContainer className="!grid-cols-2">
                    <MetricElement
                        title="Total de pagamentos"
                        subtitle="Quantidade total de pagamentos, incluindo não aprovados"
                        icon="fa fa-fw fa-shopping-cart"
                        value={dashboard.isLoading ? "..." : metrics?.payments?.total}
                    />

                    <MetricElement
                        title="Pagamentos aprovados"
                        subtitle="Quantidade de pagamentos aprovados (compras)"
                        icon="fa fa-fw fa-shopping-cart"
                        value={dashboard.isLoading ? "..." : metrics?.payments?.approved}
                    />
                </MetricsContainer>
            </div>

            <MetricsContainer className="!grid-cols-2">
                <MetricElement
                    title="Títulos aprovados"
                    subtitle="Quantidade de títulos aprovados"
                    icon="fa fa-fw fa-arrow-up-1-9"
                    value={dashboard.isLoading ? "..." : metrics?.numbers?.delivered}
                />

                <MetricElement
                    title="Títulos aguardando entrega"
                    subtitle="Quantidade de títulos que foram aprovados e estão aguardando entrega"
                    icon="fa fa-fw fa-arrow-down-1-9"
                    value={dashboard.isLoading ? "..." : metrics?.numbers?.waitingDelivery}
                    link={`/#/approvedPaymentsWithoutNumbers?filter={"raffleId": ${dashboard?.raffleId}}`}
                />
            </MetricsContainer>
        </div>
    );
};