import { AutocompleteInput, BooleanInput, CloneButton, ImageField, ImageInput, ReferenceInput, SelectInput, SimpleForm, TextInput } from "ra-ui-materialui";
import { useCallback, useEffect, useState } from "react";
import { FormDataConsumer, useEditContext } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { createDataProviderRequest } from "../../../../core/Actions";
import { BrazilianStates } from "../../../../core/BrazilianStates";

export const ShowReseller = () => {
    const [hasReseller, setHasReseller] = useState<boolean | null>(null);
    const formContext = useFormContext();
    const { getValues, setValue } = formContext;
    const isEdit = Boolean(useEditContext().record);
    const resellerSearch = useWatch({ name: "resellerSearch", control: formContext.control });

    // Use useCallback to avoid creating a new function every render
    const fetchResellerData = useCallback(async (resellerSearch: string) => {
        if (resellerSearch) {
            try {
                const response = await createDataProviderRequest("get", "affiliates", null, {
                    query: { filter: { id: resellerSearch } }
                });
                const data = await response.json();

                // If the user exists, it will set the values of the user in the form
                if (data.length > 0) {
                    setValue("reseller.name", data[0].name);
                }
            } catch (error) {
                console.error("Failed to fetch reseller data", error);
            }
        }
    }, [setValue]);

    useEffect(() => {
        // If it is not in edit mode, it will not reassign the value
        if (isEdit) {
            const resellerValue = getValues().reseller?.name;
            setHasReseller(Boolean(resellerValue));
            setValue("hasReseller", resellerValue !== null);
        }
    }, [isEdit, getValues, setValue]);

    // If the hasReseller change to false, it will set the reseller value to null
    useEffect(() => {
        if (!hasReseller) {
            setValue("resellerSearch", null);
            setValue("reseller", null);
        }
    }, [hasReseller, setValue]);

    // Fetch the reseller data when the user searches for a reseller
    useEffect(() => {
        fetchResellerData(resellerSearch);
    }, [resellerSearch, fetchResellerData]);

    return (
        <>
            <BooleanInput
                fullWidth
                label="Revendedor"
                defaultValue={hasReseller}
                source="hasReseller"
                onChange={(e) => setHasReseller(e.target.checked)}
            />

            {
                hasReseller && (
                    <>
                        <div className="w-full p-4 mb-4 bg-slate-100 rounded-2xl">
                            <span>Buscar pelo revendedor (opcional):</span>

                            <ReferenceInput
                                key="affiliateName"
                                source="resellerSearch"
                                reference="affiliates"
                            >
                                <AutocompleteInput
                                    optionText={(record) => `${record.name} - ${record.document}`}
                                    fullWidth
                                    source="name,cpf,slug"
                                    label="Nome, CPF ou identificador do revendedor"
                                    helperText="Digite o nome, CPF ou identificador do revendedor para buscar (Apenas nome será salvo)"
                                />
                            </ReferenceInput>
                        </div>

                        <TextInput
                            fullWidth
                            label="Nome do revendedor"
                            source="reseller.name"
                        />
                    </>
                )
            }
        </>
    );
};

export const UserInfo = () => {
    const { setValue } = useFormContext();
    const [userCPF, setUserCPF] = useState<string>("");

    // Use useCallback to avoid creating a new function every render
    const fetchUserData = useCallback(async (cpf) => {
        if (cpf.length === 11) {
            try {
                const response = await createDataProviderRequest("get", "users", null, {
                    query: {
                        filter: { cpf }
                    }
                });

                const data = await response.json();

                if (data.length > 0) {
                    const user = data[0];
                    setValue("winner.name", user.name);
                    setValue("location.state", user.state);
                    setValue("location.city", user.city);
                    setValue("location.neighborhood", user.address);
                }
            } catch (error) {
                console.error("Failed to fetch user data", error);
            }
        }
    }, [setValue]);

    // Fetch the user data when the user types the CPF
    useEffect(() => {
        fetchUserData(userCPF);
    }, [userCPF, fetchUserData]);

    return (
        <>
            <div className="w-full p-4 mb-4 bg-slate-100 rounded-2xl">
                <span>Buscar pelo CPF (opcional):</span>

                <TextInput
                    fullWidth
                    source="resellerCPF"
                    label="CPF do ganhador"
                    helperText="Digite o CPF do usuário para buscar (O CPF não será salvo, usado apenas para busca)"
                    onChange={(e) => setUserCPF(e.target.value)}
                    inputProps={{ maxLength: 11 }}
                />
            </div>

            <TextInput
                fullWidth
                label="Nome do ganhador"
                source="winner.name"
            />

            <ImageInput
                fullWidth
                accept="image/png, image/jpg, image/jpeg"
                source="winnerPhotoUrl"
            >
                <ImageField fullWidth source="src" title="title"  />
            </ImageInput>

            <SelectInput
                label="Estado"
                source="location.state"
                fullWidth
                choices={BrazilianStates}
            />

            <TextInput
                fullWidth
                label="Cidade"
                source="location.city"
            />

            <TextInput
                fullWidth
                label="Bairro"
                source="location.neighborhood"
            />
        </>
    );
};

export const RaffleExternalResultsForm = () => {
    const { record } = useEditContext();
    const isEdit = Boolean(record);

    return (
        <SimpleForm>
            <div className="flex justify-end w-full gap-2">
                <CloneButton
                    label="Duplicar resultado"
                    disabled={!isEdit}
                />

                <CloneButton
                    label="Adicionar mais um resultado"
                    disabled={!isEdit}
                    record={{ raffleId: record?.raffleId }}
                />
            </div>

            <ReferenceInput reference="raffles" source="raffleId">
                <AutocompleteInput optionText="title" fullWidth label="Sorteio" />
            </ReferenceInput>

            <FormDataConsumer>
                {({ formData }) => formData.raffleId && (
                    <ReferenceInput
                        filter={{ raffleId: formData.raffleId }}
                        reference="rafflePrizes"
                        source="prizeId"
                    >
                        <AutocompleteInput optionText="name" fullWidth label="Prêmio" />
                    </ReferenceInput>
                )}
            </FormDataConsumer>

            <TextInput
                fullWidth
                label="Número do ganhador"
                source="luckyNumber"
                inputProps={{ maxLength: 7 }}
            />

            <UserInfo />
            <ShowReseller />
        </SimpleForm>
    )
}