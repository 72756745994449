import { AutocompleteInput, NumberInput, ReferenceInput, SimpleForm } from "ra-ui-materialui";
import { minValue, required } from "react-admin";

export const AffiliateBatchesForm = (props: { mode: string }) => {
    return (
        <SimpleForm>
            <ReferenceInput reference="managedPhysicalRanges" source="managedRangeId" validate={[required()]}>
                <AutocompleteInput fullWidth optionText={(c) => `ID ${c.id} (de ${c.fromBatch} a ${c.toBatch})`} />
            </ReferenceInput>

            <NumberInput fullWidth source="batchNumber" validate={[required(), minValue(1)]} />

            <ReferenceInput reference="affiliates" source="affiliateId" validate={[required()]}>
                <AutocompleteInput fullWidth optionText="name" />
            </ReferenceInput>

            <ReferenceInput
                reference="affiliates"
                source="managerId"
                filter={{ role: ["MANAGER", "COORDINATOR"] }}
                validate={[required()]}
            >
                <AutocompleteInput
                    fullWidth

                    optionText={(record) => {
                        const i18nRoles = {
                            MANAGER: "Gerente",
                            COORDINATOR: "Coordenador"
                        };

                        return `${record.name} (${i18nRoles[record.role] || record.role})`;
                    }}
                />
            </ReferenceInput>
        </SimpleForm>
    );
}
