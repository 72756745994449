import { Edit } from "react-admin";
import { AffiliateForm } from "./AffiliateForm";

export const AffiliateEdit = () => {
    return (
        <Edit>
            <AffiliateForm />
        </Edit>
    );
};
