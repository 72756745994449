import { Typography } from "@mui/material";
import { AutocompleteInput, BooleanInput, Button, DateTimeInput, FormDataConsumer, ImageField, ImageInput, NumberInput, ReferenceInput, SaveButton, SelectInput, TabbedForm, TextInput, Toolbar, maxLength, minLength, required } from "react-admin";
import { useFormContext } from "react-hook-form";
import { BrazilianStates } from "../../../../core/BrazilianStates";
import { RafflePrizeKind } from "../../Raffles";
import { Add, Delete } from "@mui/icons-material";
import { Grid, IconButton, TextField as MuiTextField } from "@mui/material";
import { useState } from "react";
import { useInput } from "react-admin";

interface KeyValue {
    key: string;
    value: string;
}

const ModifiersInput = (props: any) => {
    const { field, isRequired } = useInput(props);
    const [pairs, setPairs] = useState<KeyValue[]>(
        Object.entries(field.value || {}).map(([key, value]) => ({ key, value })) as any
    );

    const handlePairChange = (index: number, key: string, value: string) => {
        const updatedPairs = [...pairs];
        updatedPairs[index] = { key, value };
        setPairs(updatedPairs);
        field.onChange(Object.fromEntries(updatedPairs.map(pair => [pair.key, pair.value])));
    };

    const handleAddPair = () => {
        setPairs([...pairs, { key: "", value: "" }]);
    };

    const handleRemovePair = (index: number) => {
        const updatedPairs = pairs.filter((_, i) => i !== index);
        setPairs(updatedPairs);
        field.onChange(Object.fromEntries(updatedPairs.map(pair => [pair.key, pair.value])));
    };

    return (
        <div>
            <Typography variant="caption" className="block">
                Multiplicadores de aposta
            </Typography>

            {pairs.map((pair, index) => (
                <Grid container spacing={2} key={index} alignItems="center">
                    <Grid item xs={5}>
                        <MuiTextField
                            inputProps={{ type: "number" }}
                            label="Quantidade de acertos"
                            value={pair.key}
                            onChange={(e) => handlePairChange(index, e.target.value, pair.value)}
                            fullWidth
                            required={isRequired}
                        />
                    </Grid>

                    <Grid item xs={5}>
                        <MuiTextField
                            inputProps={{ type: "number" }}
                            label="Multiplicador"
                            value={pair.value}
                            onChange={(e) => handlePairChange(index, pair.key, e.target.value)}
                            fullWidth
                            required={isRequired}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <IconButton onClick={() => handleRemovePair(index)} color="secondary">
                            <Delete />
                        </IconButton>
                    </Grid>
                </Grid>
            ))}

            <Button
                label="Adicionar multiplicador"
                onClick={handleAddPair}
                color="primary"
                startIcon={<Add />}
            />

            <Typography variant="caption">{props.helperText}</Typography>
        </div>
    );
};

export const RafflePrizeForm = (props: {
    kind: "CREATE" | "EDIT"
}) => {
    return (
        <TabbedForm
            toolbar={
                <>
                    <Toolbar>
                        <SaveButton alwaysEnable label={props.kind === "CREATE" ? "Criar" : "Editar"} />
                    </Toolbar>
                </>
            }
        >
            <TabbedForm.Tab label="Dados">
                <TextInput fullWidth source="name" validate={[required()]} helperText="Dê um nome ao prêmio" />

                <TextInput fullWidth source="siteName" validate={[required()]} helperText="Dê um nome ao prêmio que aparecerá no site" />

                <SelectInput fullWidth source="raffleKind" choices={RafflePrizeKind} validate={[required()]} helperText="O tipo de execução do sorteio relacionado à este prêmio" />

                <ImageInput
                    fullWidth
                    accept="image/png, image/jpg, image/jpeg"
                    source="thumbnail"
                >
                    <ImageField fullWidth source="src" title="title" />
                </ImageInput>

                <NumberInput fullWidth source="displayOrder" helperText="A ordem / posição no qual este prêmio será exibido" />

                <BooleanInput source="unlisted" helperText="Caso marcado, não aparecerá em listagens públicas" />

                <ReferenceInput source="raffleId" reference="raffles" validate={[required()]}>
                    <AutocompleteInput fullWidth optionText="title" helperText="O sorteio relacionado à este prêmio" />
                </ReferenceInput>

                <FormDataConsumer>
                    {({ formData }) =>
                        ["LUCKY_NUMBERS", "BY_SELL_ORDER", "FEDERAL_LOTTO_RANDOM", "FEDERAL_LOTTO_RANDOM_ALT"].includes(formData.raffleKind) ? (
                            <SelectInput
                                source="winnersAmount"
                                helperText={
                                    <span>
                                        {"A quantidade de ganhadores sorteados neste prêmio."}
                                        <br/>
                                        {"Caso seja selecionado mais de um ganhador, será utilizado o layout de sorteio múltiplo no sorteador"}
                                    </span>
                                }
                                required
                                defaultValue={1}
                                choices={[
                                    { id: 1, name: 1 },
                                    { id: 5, name: 5 },
                                    { id: 10, name: 10 },
                                    { id: 15, name: 15 },
                                    { id: 20, name: 20 },
                                    { id: 25, name: 25 }
                                ]}
                            />
                        ) : null
                    }
                </FormDataConsumer>
            </TabbedForm.Tab>

            <TabbedForm.Tab label="Configurações de sorteio">
                <PrizeConfigsTab />
            </TabbedForm.Tab>
        </TabbedForm>
    )
};

const PrizeConfigsTab = () => {
    const form = useFormContext();

    const addModifier = (formData: any) => {
        const currentMultipliers = Object.keys(formData.data.gambling?.multipliers || {}).map(Number);
        const highestMultiplier = Math.max(0, ...currentMultipliers);

        const newKey = highestMultiplier + 1;
        const newValue = 1;

        console.debug("New key:", newKey);

        form.setValue("data.gambling.multipliers", {
            ...formData.data.gambling?.multipliers,
            [newKey]: newValue
        }, {
            shouldDirty: true,
            shouldValidate: true,
            shouldTouch: true
        });
    };

    return <>
        <DateTimeInput
            fullWidth
            source="data.drawRules.startAt"
            helperText="A data e hora final para filtrar os pagamentos ao procurar pelos ganhadores."
        />

        <DateTimeInput
            fullWidth
            source="data.drawRules.endAt"
            helperText="A data e hora inicial para filtrar os pagamentos ao procurar pelos ganhadores."
        />

        <FormDataConsumer>
            {({ formData }) => (
                <>
                    <SelectInput
                        fullWidth
                        disabled={!formData.raffleKind || formData.raffleKind === "MATRIXES"}
                        source="data.drawRules.userState"
                        choices={ [...BrazilianStates, { name: "Qualquer", value: undefined }] }
                        label="Estado (UF)"
                        helperText="Estado em que o ganhador deve pertencer"
                        emptyText="Qualquer"
                        placeholder="Qualquer"
                        validate={[minLength(2), maxLength(2)]}
                        onChange={(event) => {
                            if (!event.target.value) {
                                form.setValue("data.drawRules.userCity.name", null);
                            }
                        }}
                    />

                    <TextInput
                        disabled={!formData.data?.drawRules?.userState || !formData.raffleKind || formData.raffleKind === "MATRIXES"}
                        source="data.drawRules.userCity.name"
                        label="Cidade"
                        helperText="Cidade em que o ganhador deve pertencer"
                        placeholder="Qualquer"
                        value={1}
                    />
                </>
            )}
        </FormDataConsumer>

        <FormDataConsumer>
            {({ formData, ...rest }) => {
                if (formData?.raffleKind !== "GAMBLING") {
                    return null;
                }

                const modifiers = formData?.gamblingConfig?.modifiers || {
                    // Uses a default invalid value, to force the user to add at least one modifier
                    0: 0
                };

                return (
                    <div className="my-3">
                        <div className="mb-3">
                            <Typography variant="h6">
                                Configurações de Aposta
                            </Typography>

                            <Typography variant="body1" className="opacity-60">
                                Configure modificadores, etc.
                            </Typography>
                        </div>

                        <ModifiersInput source="data.gambling.multipliers" />
                    </div>
                );
            }}
        </FormDataConsumer>
    </>
}
