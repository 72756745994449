import { Typography } from "@mui/material";
import { Edit } from "react-admin";
import { ManagedBatchesForm } from "./ManagedBatchesForm";

export const ManagedBatchesEdit = () => (
    <Edit redirect="show">
        <Typography variant="h5" className="px-4 pt-4" mb={3}> Editar lote </Typography>
        <ManagedBatchesForm mode="edit" />
    </Edit>
);
