import { Typography } from "@mui/material";
import { Edit } from "react-admin";
import { AffiliateBatchesForm } from "./AffiliateBatchesForm";

export const AffiliateBatchesEdit = () => (
    <Edit redirect="show">
        <Typography variant="h5" className="px-4 pt-4" mb={3}>Editar lote</Typography>
        <AffiliateBatchesForm mode="edit" />
    </Edit>
);
