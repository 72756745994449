import { Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { AutocompleteInput, email, maxLength, minLength, number, PasswordInput, regex, required, SelectInput, SimpleForm, TextInput, useEditContext } from "react-admin";
import { useFormContext } from "react-hook-form";
import { createDataProviderRequest } from "../../../core/Actions";
import { BrazilianStates } from "../../../core/BrazilianStates";
import { ResellerRoles } from "../../../core/ResellerRoles";
import { AffiliateRegistrationStatus } from "../Affiliates";

interface Affiliate {
    id: number;
    name: string;
    role: string;
}

export const ManagerCoordinatorInputs = () => {
    const [managers, setManagers] = useState<Affiliate[]>([]);
    const [coordinators, setCoordinators] = useState<Affiliate[]>([]);

    const formContext = useFormContext();
    const formData = formContext?.getValues();
    const role = formContext.watch("role");

    const fetchAffiliates = async () => {
        try {
            const response = await createDataProviderRequest("get", "affiliates", null, {
                query: {
                    filter: {
                        role: ["MANAGER", "COORDINATOR"]
                    },

                    attributes: ["id", "name", "role"]
                }
            });

            const data = await response.json();

            setManagers(data.filter((affiliate) => affiliate.role === "MANAGER"));
            setCoordinators(data.filter((affiliate) => affiliate.role === "COORDINATOR"));

        } catch (error) {
            console.error("Failed to fetch affiliates:", error);
        }
    };

    useEffect(() => {
        fetchAffiliates();
    }, []);

    useEffect(() => {
        if (formData?.role === "MANAGER") {
            formContext.setValue("data.managerId", null);
            formContext.setValue("data.coordinatorId", null);
        }

        if (formData?.role === "COORDINATOR") {
            formContext.setValue("data.coordinatorId", null);
        }
    }, [role]);

    const managerChoices = useMemo(() => {
        return managers.map((manager) => {
            return { id: manager.id, name: manager.name };
        });
    }, [managers]);

    const coordinatorChoices = useMemo(() => {
        return coordinators.map((coordinator) => {
            return { id: coordinator.id, name: coordinator.name };
        });
    }, [coordinators]);

    return (
        <div className="flex flex-wrap w-full gap-3">
            <AutocompleteInput
                source="data.managerId"
                defaultValue={formData.data?.managerId}
                className="flex-1"
                choices={managerChoices}
                disabled={formData.role === "MANAGER"}
            />

            <AutocompleteInput
                source="data.coordinatorId"
                defaultValue={formData.data?.coordinatorId}
                className="flex-1"
                choices={coordinatorChoices}
                disabled={["COORDINATOR", "MANAGER"].includes(formData.role)}
            />
        </div>
    );
};

export const AffiliateForm = () => {
    const isEdit = Boolean(useEditContext().record);

    return (
        <SimpleForm>
            <Typography variant="h5" mb={3}>
                {isEdit ? "Editar" : "Criar"} Revendedor
            </Typography>

            <div className="flex-1">
                <SelectInput
                    choices={ ResellerRoles }
                    fullWidth
                    label="Cargo"
                    source="role"
                    validate={[required()]}
                />
            </div>

            <ManagerCoordinatorInputs />

            <TextInput fullWidth source="name" />

            <TextInput
                fullWidth
                label="CPF/CNPJ"
                source="document"
                inputProps={{ maxLength: 14 }}
                parse={(value) => value.replace(/[^0-9]/g, "")}
                validate={[required(), regex(/([0-9\.\-]+)/)]}
                helperText="Não é necessário inserir hífen ou pontos"
            />

            <TextInput
                fullWidth
                source="email"
                type="email"
                validate={[required(), email()]}
            />

            <TextInput
                fullWidth
                label="Telefone"
                source="tel"
                validate={[required(), number("Deve conter somente números"), maxLength(11), minLength(9)]}
            />

            <TextInput
                fullWidth
                label="Identificador"
                source="slug"
                validate={required()}
                helperText={"Por exemplo, \"bancajose\", \"joao-silva123\"."}
            />

            <PasswordInput fullWidth source="password" />

            <TextInput
                fullWidth
                label="CEP"
                source="cep"
                parse={(value) => value.replace(/[^0-9]/g, "")}
                validate={[required(), regex(/[0-9][0-9]\.?[0-9][0-9][0-9]\-?[0-9][0-9][0-9]/, "O CEP inserido é inváldo.")]}
                helperText="Não é necessário inserir hífen ou pontos"
            />

            <SelectInput
                source="registrationStatus"
                validate={[required()]}
                choices={AffiliateRegistrationStatus}
            />

            <div className="flex w-full">
                <div className="w-2/3 pr-3">
                    <TextInput fullWidth label="Endereço completo" source="address" validate={required()} />
                </div>

                <div className="flex-1">
                    <TextInput fullWidth label="Bairro" source="neighborhood" validate={required()} />
                </div>
            </div>

            <div className="flex w-full">
                <div className="w-2/3 pr-3">
                    <TextInput fullWidth label="Cidade" source="city" validate={required()} />
                </div>

                <div className="flex-1">
                    <SelectInput
                        choices={ BrazilianStates }
                        fullWidth
                        label="Estado (UF)"
                        source="state"
                        validate={[required(), minLength(2), maxLength(2)]}
                    />
                </div>
            </div>

            { 
                isEdit && (
                    <TextInput
                        fullWidth
                        label="Comissão"
                        source="commission"
                        helperText="A porcentagem de comissão do revendedor. Deixe em branco para o valor padrão do sistema."

                        format={(v) => {
                            if (v === undefined || v === null) {
                                return "";
                            }

                            return (Number(v) * 100) + "%";
                        }}

                        parse={(v) => {
                            if (v === "" || v === undefined || v === null) {
                                return undefined;
                            }

                            // Grab only numeric values
                            v = v.replace(/[^0-9.,]/g, "");

                            return Number(v) / 100;
                        }}
                    />
                )
            }

            <TextInput
                fullWidth
                label="Chave PIX"
                source="data.PIXKey"
                helperText="A chave pix deve estar no mesmo nome do titular da conta."
            />
        </SimpleForm>
    );
};
