import StraightenIcon from "@mui/icons-material/Straighten";
import { AffiliateBatchesCreate } from "./affiliates/affiliateBatches/AffiliateBatchesCreate";
import { AffiliateBatchesEdit } from "./affiliates/affiliateBatches/AffiliateBatchesEdit";
import { AffiliateBatchesList } from "./affiliates/affiliateBatches/AffiliateBatchesList";

export const AffiliateBatchesResource = {
    create: AffiliateBatchesCreate,
    edit: AffiliateBatchesEdit,
    list: AffiliateBatchesList,
    icon: StraightenIcon
};
