import { Datagrid, NumberField, ReferenceField, TextField } from "react-admin";

export const PhysicalSalesTab = (props: {
    metrics: Record<string, any> | null;
}) => {
    // Converts the report to a Datagrid-compatible format
    const physicalBondsPerReseller = Object.entries(props.metrics?.physicalBondsPerReseller ?? {}).map(([id, report]) => ({
        ...report as any,
        affiliateId: Number(id)
    }));

    return (
        <div>
            {
                (physicalBondsPerReseller.length)
                    ? (
                        <>
                            <Datagrid data={physicalBondsPerReseller} sort={{ field: "soldBonds", order: "DESC" }}>
                                <ReferenceField label="Revendedor" reference="affiliates" source="affiliateId" link="show">
                                    <TextField source="name" />
                                </ReferenceField>

                                <NumberField label="Títulos vendidos" source="soldBonds" />
                                <NumberField label="Pagamentos" source="paymentsCount" />
                                <NumberField label="Estoque" source="bondsHeld" />
                                <NumberField label="Total em R$ por títulos" source="bondsTotalValue" locales="pt-BR" options={{ style: 'currency', currency: 'BRL' }} />
                            </Datagrid>
                        </>
                    )
                    : (
                        <div className="p-3 text-center">
                            <span className="block mt-2 text-base text-red-500">De acordo com os filtros selecionados, nenhuma venda física foi feita.</span>
                        </div>
                    )
            }
        </div>
    );
}
