import ConfirmationNumber from "@mui/icons-material/ConfirmationNumber";
import EmojiEvents from "@mui/icons-material/EmojiEvents";
import Leaderboard from "@mui/icons-material/Leaderboard";
import MilitaryTech from "@mui/icons-material/MilitaryTech";

import { RaffleLuckyNumberList } from "./raffles/numbers/RaffleLuckyNumberList";
import { RaffleLuckyNumberShow } from "./raffles/numbers/RaffleLuckyNumberShow";

import { RafflePrizeCreate } from "./raffles/prizes/RafflePrizeCreate";
import { RafflePrizeEdit } from "./raffles/prizes/RafflePrizeEdit";
import { RafflePrizeList } from "./raffles/prizes/RafflePrizeList";
import { RafflePrizeShow } from "./raffles/prizes/RafflePrizeShow";

import { RaffleCreate } from "./raffles/RaffleCreate";
import { RaffleEdit } from "./raffles/RaffleEdit";
import { RaffleList } from "./raffles/RaffleList";
import { RaffleShow } from "./raffles/RaffleShow";

import { RaffleResultCreate } from "./raffles/results/RaffleResultCreate";
import { RaffleResultEdit } from "./raffles/results/RaffleResultEdit";
import { RaffleResultsList } from "./raffles/results/RaffleResultsList";

import { ArrowOutward } from "@mui/icons-material";
import { RaffleExternalResultsCreate } from "./raffles/external-results/RaffleExternalResultsCreate";
import { RaffleExternalResultsEdit } from "./raffles/external-results/RaffleExternalResultsEdit";
import { RaffleExternalResultsList } from "./raffles/external-results/RaffleExternalResultsList";

export const RafflesResource = {
    list: RaffleList,
    edit: RaffleEdit,
    icon: Leaderboard,
    show: RaffleShow,
    create: RaffleCreate
}

export const RaffleLuckyNumbersResource = {
    list: RaffleLuckyNumberList,
    icon: ConfirmationNumber,
    show: RaffleLuckyNumberShow
}

export const RafflePrizesResource = {
    create: RafflePrizeCreate,
    edit: RafflePrizeEdit,
    list: RafflePrizeList,
    show: RafflePrizeShow,
    icon: MilitaryTech
}

export const RaffleResultsResource = {
    create: RaffleResultCreate,
    list: RaffleResultsList,
    edit: RaffleResultEdit,
    icon: EmojiEvents
}

export const RaffleExternalResultsResource = {
    create: RaffleExternalResultsCreate,
    list: RaffleExternalResultsList,
    edit: RaffleExternalResultsEdit,
    icon: ArrowOutward
}

export const RaffleStatus = [
    { id: "DRAFT", name: "Rascunho (invisível)" },
    { id: "WAITING", name: "Aguardando (visível, mas não comprável)" },
    { id: "PRIVATE", name: "Privado (comprável somente através de link direto)" },
    { id: "OPEN", name: "Aberto (visível e comprável)" },
    { id: "CLOSED", name: "Fechado (visível e não comprável)" },
    { id: "SUSPENDED", name: "Suspenso (visível e não comprável)" },
    { id: "ENDED", name: "Finalizado (visível somente já comprados)" }
]

export const RaffleKinds = [
    { id: "MAIN", name: "Principal" },
    { id: "SECONDARY", name: "Secundário" },
    { id: "GAMBLING", name: "Apostas" }
]

export const RafflePrizeKind = [
    { id: "LUCKY_NUMBERS", name: "Números da sorte" },
    { id: "MATRIXES", name: "Matriz / dezenas" },
    { id: "BY_SELL_ORDER", name: "Por ordem de compra" },
    { id: "FEDERAL_LOTTO_RANDOM", name: "Randômico via Loteria Fed. (até 999.999)" },
    { id: "FEDERAL_LOTTO_RANDOM_ALT", name: "Randômico via Loteria Fed. (até 9.999.999)" },
    { id: "GAMBLING", name: "Apostas" }
]
