import { Typography } from "@mui/material";
import { Create } from "react-admin";
import { ManagedBatchesForm } from "./ManagedBatchesForm";

export const ManagedBatchesCreate = () => {
    return (
        <Create redirect="show">
            <Typography variant="h5" className="px-4 pt-4" mb={3}> Adicionar lote </Typography>
            <ManagedBatchesForm mode="create" />
        </Create>
    );
};
