import { Create } from "react-admin";

import { RaffleForm } from "./RaffleForm";

export const RaffleCreate = () => {
    return (
        <Create>
            <RaffleForm />
        </Create>
    );
}
