import { Typography } from "@mui/material";
import { Create } from "react-admin";
import { AffiliateBatchesForm } from "./AffiliateBatchesForm";

export const AffiliateBatchesCreate = () => {
    return (
        <Create redirect="show">
            <Typography variant="h5" className="px-4 pt-4" mb={3}>Adicionar lote</Typography>
            <AffiliateBatchesForm mode="create" />
        </Create>
    );
};
