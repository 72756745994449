import { Datagrid, List, NumberField, ReferenceField, TextField } from "react-admin";

export const AffiliateBatchesList = () => (
    <List>
        <Datagrid rowClick="show">
            <NumberField source="batchNumber" />

            <ReferenceField source="affiliateId" reference="affiliates">
                <TextField source="name" />
            </ReferenceField>

            <ReferenceField source="managerId" reference="affiliates">
                <TextField source="name" />
            </ReferenceField>
        </Datagrid>
    </List>
);
