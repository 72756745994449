import { Typography } from "@mui/material";
import { Edit } from "react-admin";
import { RaffleCategoriesForm } from "./RaffleCategoriesForm";

export const RaffleCategoriesEdit = () => (
    <Edit>
        <Typography variant="h5" className="px-4 pt-4" mb={3}> Editar categoria </Typography>
        <RaffleCategoriesForm />
    </Edit>
);
