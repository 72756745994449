import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
    Box,
    Button,
    IconButton,
    Input,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNotify } from "react-admin";
import { createDataProviderRequest } from "../../../core/Actions";

export const AdminMFA = () => {
    const [secret, setSecret] = useState<string | null>(null);
    const [qr, setQr] = useState<string | null>(null);
    const [otp, setOtp] = useState<string | null>(null);
    const [tokens, setTokens] = useState<{
        uuid: string;
        createdAt: string;
    }[]>([]);
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const notify = useNotify();

    useEffect(() => {
        fetchMFAInfo();
    }, []);

    const fetchMFAInfo = () => {
        createDataProviderRequest("post", "/admins/mfa")
            .then((res) => res.json())
            .then((res) => {
                setSecret(res.secret);
                setQr(res.qr);
            });

        createDataProviderRequest("get", "/admins/mfa")
            .then((res) => res.json())
            .then((res) => setTokens(res));
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        createDataProviderRequest("post", "/admins/mfa/confirm", {
            otp,
            secret,
        })
            .then(() => {
                notify("Código de autenticação confirmado.", { type: "success" });
                setOtp(null);
                setModalOpen(false); // Close the modal after successful confirmation
                fetchMFAInfo(); // Refresh the token list after adding a new device
            })
            .catch((e) => {
                notify("Código de autenticação inválido.", { type: "error" });
                console.error(e);
            });
    };

    const removeToken = (uuid: string) => {
        createDataProviderRequest("delete", `/admins/mfa/token/${uuid}`)
            .then(() => {
                setTokens(tokens.filter((token) => token.uuid !== uuid));
                notify("Dispositivo removido com sucesso.", { type: "success" });
            })
            .catch((e) => {
                notify("Ocorreu um erro ao remover o dispositivo.", { type: "error" });
                console.error(e);
            });
    };

    const copySecret = () => {
        if (secret) {
            navigator.clipboard.writeText(secret);
            notify("Segredo copiado para a área de transferência.", { type: "info" });
        }
    };

    return (
        <Box py={5}>
            <Typography variant="h6" gutterBottom>
                Dispositivos conectados
            </Typography>

            <Box mb={5}>
                {!tokens.length ? (
                    <Typography>Você não tem dispositivos conectados.</Typography>
                ) : (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>UUID</TableCell>
                                <TableCell>Criado em</TableCell>
                                <TableCell align="right">Ação</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tokens.map((token) => (
                                <TableRow key={token.uuid}>
                                    <TableCell>{token.uuid}</TableCell>
                                    <TableCell>{token.createdAt}</TableCell>
                                    <TableCell align="right">
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => removeToken(token.uuid)}
                                        >
                                            Remover
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </Box>

            <Button
                variant="contained"
                color="primary"
                onClick={() => setModalOpen(true)}
            >
                Adicionar Dispositivo
            </Button>

            {/* Modal with background and centered content */}
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '90%',
                        maxWidth: 400,
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        Conectar dispositivo
                    </Typography>

                    <Typography variant="body1" paragraph>
                        Escaneie o código QR abaixo em um aplicativo de autenticação de dois fatores e insira o código de autenticação gerado.
                    </Typography>

                    {qr && (
                        <Box display="flex" justifyContent="center" mb={5}>
                            <img src={qr} alt="QR Code" style={{ width: "20vmax" }} />
                        </Box>
                    )}

                    {secret && (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column", // Stack the input and button vertically
                                alignItems: "flex-start",
                                mb: 2,
                            }}
                        >
                            <Typography variant="body1" paragraph>
                                Caso não possa escanear o código, insira este segredo manualmente:
                            </Typography>
                            <Box sx={{ display: "flex", alignItems: "center", width: '100%' }}>
                                <Input
                                    fullWidth
                                    type="text"
                                    value={secret}
                                    readOnly
                                    sx={{
                                        mr: 1,
                                        backgroundColor: "#f3f3f3",
                                        padding: "10px",
                                        borderRadius: 1,
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        fontFamily: "monospace",
                                    }}
                                />
                                <IconButton onClick={copySecret}>
                                    <ContentCopyIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    )}

                    {qr && (
                        <form onSubmit={onSubmit}>
                            <Box mb={5}>
                                <Input
                                    fullWidth
                                    type="text"
                                    placeholder="Código de autenticação"
                                    value={otp || ""}
                                    onChange={(e) => setOtp(e.target.value)}
                                />
                            </Box>

                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={!otp || !secret}
                            >
                                Confirmar
                            </Button>
                        </form>
                    )}
                </Box>
            </Modal>
        </Box>
    );
};
