import StraightenIcon from "@mui/icons-material/Straighten";
import { ManagedBatchesCreate } from "./affiliates/managedBatches/ManagedBatchesCreate";
import { ManagedBatchesEdit } from "./affiliates/managedBatches/ManagedBatchesEdit";
import { ManagedBatchesList } from "./affiliates/managedBatches/ManagedBatchesList";
import { ManagedBatchesShow } from "./affiliates/managedBatches/ManagedBatchesShow";

export const ManagedBatchesResource = {
    show: ManagedBatchesShow,
    create: ManagedBatchesCreate,
    edit: ManagedBatchesEdit,
    list: ManagedBatchesList,
    icon: StraightenIcon
};

export enum BatchesKindsEnum {
    NORMAL_SALE = "NORMAL_SALE",
    FAST_SALE = "FAST_SALE",
    PHYSICAL_SALE = "PHYSICAL_SALE"
}

interface BatchsRules {
    hasExpirationAt: boolean;
    requiresRaffleId: boolean;
}

export const BatchesRules: Record<string, BatchsRules> = {
    [BatchesKindsEnum.NORMAL_SALE]: {
        hasExpirationAt: true,
        requiresRaffleId: false
    },

    [BatchesKindsEnum.FAST_SALE]: {
        hasExpirationAt: true,
        requiresRaffleId: false
    },

    [BatchesKindsEnum.PHYSICAL_SALE]: {
        hasExpirationAt: false,
        requiresRaffleId: true
    }
};
