import { CloneButton } from "ra-ui-materialui/dist/cjs/button/CloneButton";
import { AutocompleteArrayInput, CreateButton, Datagrid, DateField, ExportButton, FilterButton, FunctionField, List, NumberField, ReferenceArrayInput, ReferenceField, TextField, TextInput, TopToolbar } from "react-admin";

const RaffleLuckyNumberFilter = [
    <ReferenceArrayInput alwaysOn source="raffleId" reference="raffles">
        <AutocompleteArrayInput fullWidth optionText="title" label="resources.userLuckyNumbers.filters.raffleTitle" />
    </ReferenceArrayInput>,

    <ReferenceArrayInput alwaysOn source="prizeId" reference="rafflePrizes">
        <AutocompleteArrayInput fullWidth optionText="name" label="Filtrar por prêmio" />
    </ReferenceArrayInput>,

    <TextInput alwaysOn source="winner.name" label="Filtrar por ganhador" />
];

const RaffleResultsActions = () => (
    <TopToolbar>
        <CreateButton />
        <ExportButton />
        <FilterButton />
    </TopToolbar>
);

export const RaffleExternalResultsList = () => (
    <List
        filters={RaffleLuckyNumberFilter}
        actions={<RaffleResultsActions />}
        perPage={10}
    >
        <Datagrid bulkActionButtons={false} rowClick="edit">
            <NumberField source="id" />

            <ReferenceField source="prizeId" reference="rafflePrizes">
                <TextField source="name" />
            </ReferenceField>

            <TextField source="luckyNumber" />

            <TextField source="winner.name" />

            <FunctionField
                label="Cidade / Estado"

                render={(record) => {
                    const city = record.location?.city;
                    const state = record.location?.state;

                    if (!city || !state) {
                        return "";
                    }

                    return `${city} / ${state}`;
                }}
            />

            <TextField source="reseller.name" />

            <DateField showTime={true} source="createdAt" />

            <ReferenceField label="Sorteio" source="prizeId" reference="rafflePrizes">
                <ReferenceField source="raffleId" reference="raffles">
                    <TextField source="title" />
                </ReferenceField>
            </ReferenceField>

            <CloneButton />
        </Datagrid>
    </List>
);
