import { Edit } from "react-admin";

import { RaffleForm } from "./RaffleForm";

export const RaffleEdit = () => {
    return (
        <Edit>
            <RaffleForm />
        </Edit>
    );
}
