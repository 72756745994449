import { AutocompleteArrayInput, DateTimeInput, NumberInput, ReferenceArrayInput, SimpleForm } from "ra-ui-materialui";
import { useEffect } from "react";
import { minValue, required, SelectInput } from "react-admin";
import { useFormContext } from "react-hook-form";
import { BatchesRules } from "../../ManagedBatches";

const BatchesKinds = [
    { id: "NORMAL_SALE", name: "Venda normal" },
    { id: "FAST_SALE", name: "Venda rápida" },
    { id: "PHYSICAL_SALE", name: "Venda física" }
];


const ExpiresAtInput = (props: { mode: string }) => {
    const formCtx = useFormContext();
    const watch = formCtx.watch;
    const kind = watch("kind");

    // Clear the form expiration date when the kind changes to physical sale
    useEffect(() => {
        if (kind && !BatchesRules[kind].hasExpirationAt) {
            formCtx.setValue("expiresAt", null);
        }
    }, [kind]);

    const validation = (props.mode === "edit" && BatchesRules[kind].hasExpirationAt) ? [required()] : [];

    return (
        <DateTimeInput
            source="expiresAt"
            validate={validation}
            label={props.mode === "create" ? "Data de expiração" : undefined}

            helperText={
                props.mode === "create"
                    ? "O sistema preencherá automaticamente caso não informado"
                    : "Obrigatório"
            }

            // Only show the expiration date input if the kind when ExpirationAt is false
            disabled={!kind || !BatchesRules[kind].hasExpirationAt}
        />
    );
};

export const ManagedBatchesForm = (props: { mode: string }) => {
    return (
        <SimpleForm>
            <div className="flex gap-3">
                <NumberInput source="fromBatch" validate={[required(), minValue(1)]} />
                <NumberInput source="toBatch" validate={[required(), minValue(1)]} />
            </div>

            <div className="flex w-full gap-3">
                <NumberInput source="bondsPerBatch" validate={[required(), minValue(1)]} />
                <NumberInput
                    source="numbersPerBond"
                    validate={[minValue(1)]}
                    helperText="Número mínimo de números por título. Atenção: esta quantidade será multiplicada pelo número de chances da matriz ou do sorteio."
                />
            </div>

            <SelectInput
                source="kind"
                choices={ BatchesKinds }
                fullWidth
                validate={[required()]}
            />

            <ExpiresAtInput mode={props.mode} />

            <ReferenceArrayInput reference="raffleCategories" source="filters.categories">
                <AutocompleteArrayInput fullWidth optionText="label" />
            </ReferenceArrayInput>
        </SimpleForm>
    );
}
