export const AccessLevelOptions = [
    { name: "Todos (disponíveis para plugin)", id: "FIXED" },
    { name: "Sobre demanda (manual)", id: "ON_DEMAND" },
    { name: "Por tipo (todos de um tipo)", id: "BY_KIND" }
];

export const RaffleKindOptions = [
    { name: "Principal", id: "MAIN" },
    { name: "Secundário", id: "SECONDARY" },
    { name: "Apostas", id: "GAMBLING" }
];
