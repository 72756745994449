import { Edit, CloneButton, TopToolbar, ShowButton } from "react-admin";
import { RafflePrizeForm } from "./RafflePrizeForm";

export const RafflePrizeEdit = () => (
    <Edit
        // Uses pessimistic so the user will not be redirected unless the request succeeds.
        // If not using pessimistic, if the server returns a treated error, the user would be redirected to the list and lose the form data.
        mutationMode="pessimistic"

        actions={
            <TopToolbar>
                <CloneButton />
                <ShowButton />
            </TopToolbar>
        }
    >
        <RafflePrizeForm kind="EDIT" />
    </Edit>
);
