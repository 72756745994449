import CategoryIcon from "@mui/icons-material/Category";
import { RaffleCategoriesList } from "./raffles/categories/RaffleCategoriesList";
import { RaffleCategoriesShow } from "./raffles/categories/RaffleCategoriesShow";
import { RaffleCategoriesCreate } from "./raffles/categories/RaffleCategoriesCreate";
import { RaffleCategoriesEdit } from "./raffles/categories/RaffleCategoriesEdit";

export const RaffleCategoriesResource = {
    show: RaffleCategoriesShow,
    create: RaffleCategoriesCreate,
    edit: RaffleCategoriesEdit,
    list: RaffleCategoriesList,
    icon: CategoryIcon
};
