import { Typography } from "@mui/material";
import { Create } from "react-admin";
import { RaffleCategoriesForm } from "./RaffleCategoriesForm";

export const RaffleCategoriesCreate = () => {
    return (
        <Create>
            <Typography variant="h5" className="px-4 pt-4" mb={3}> Adicionar categoria </Typography>
            <RaffleCategoriesForm />
        </Create>
    );
};
