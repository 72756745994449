import { useMemo } from "react";
import defaultPatternBackground from "../../../assets/viva-sorte/image-generator/default-pattern.jpg";
import { Configuration } from "../../../config";
import { Ball } from "./Ball";
import { PAGE_HEIGHT, PAGE_WIDTH } from "./Constants";
import { useResultGeneratorConfig } from "./ResultGeneratorContext";
import { ResultGeneratorBlockProps } from "./ResultGeneratorPart";
import { getPrizeKindInfo } from "./ResultGeneratorHelpers";

const BASE_X_MARGIN = (PAGE_WIDTH * 0.075);
const BASE_Y_MARGIN = (PAGE_HEIGHT * 0.025);

/**
 * Used to generate a SVG image to be rendered later.
 * @param props The props to be passed to the image generator.
 * @returns
 */
export const ResultGeneratorImage = (props: ResultGeneratorBlockProps) => {
    const config = useResultGeneratorConfig();

    const pageIndex = useMemo(() => props.pageNumber - 1, [props.pageNumber]);
    const hasDrawnNumbers = useMemo(() => getPrizeKindInfo(props.prize.raffleKind)?.hasMatrix, [props.prize]);

    const file = useMemo(() =>
        config.patternBackgroundFile ?? defaultPatternBackground,
        [config.patternBackgroundFile]
    );

    return (
        <svg
            viewBox={`0 0 ${PAGE_WIDTH} ${PAGE_HEIGHT}`}
            className={["w-full h-full", "page-" + pageIndex].join(" ")}
        >
            <foreignObject
                name="backgroundImage"
                width="100%"
                height="100%"
            >
                <img
                    src={file}
                    crossOrigin="anonymous"
                    width="100%"
                    height="100%"
                />
            </foreignObject>

            <foreignObject
                name="contents"
                width={PAGE_WIDTH - (BASE_X_MARGIN * 2)}
                height={PAGE_HEIGHT - (BASE_Y_MARGIN * 2)}
                x={BASE_X_MARGIN}
                y={BASE_Y_MARGIN}
            >
                {/* Align everything in the middle*/}
                <div className="flex items-center justify-center w-full h-full">
                    <div className="w-full">
                        <div className="flex flex-col">
                            <h1
                                className="-mb-3 font-bold text-center uppercase"
                                style={{
                                    color: config.colors?.primary,
                                    fontSize: 40,
                                    fontFamily: 'Tilt Warp'
                                }}
                            >
                                { config.title || "Resultados do sorteio" }
                            </h1>

                            { config.date &&
                                <h1
                                    className="font-bold text-center uppercase"
                                    style={{
                                        color: config.colors?.secondary,
                                        fontSize: 40,
                                        fontFamily: 'Tilt Warp'
                                    }}
                                >
                                    { config.date }
                                </h1>
                            }

                            { config.subtitle &&
                                <h2
                                    className="font-bold text-center uppercase"
                                    style={{
                                        color: config.colors?.primary,
                                        fontSize: 20,
                                        fontFamily: 'Tilt Warp'
                                    }}
                                >
                                    { config.subtitle }
                                </h2>
                            }

                            <h3
                                className="font-bold text-center uppercase"
                                style={{
                                    color: config.colors?.secondary,
                                    fontSize: 20,
                                    fontFamily: 'Tilt Warp'
                                }}
                            >
                                {
                                    config.raffleIdentifier === "ALL" ? ((
                                        props.prize.time
                                        ? `${props.prize.time}H`
                                        : `${props.prize.order}º`
                                    ) + " - ") : ""
                                }
                                { props.prize.name }
                            </h3>
                        </div>

                        {
                            hasDrawnNumbers &&
                                <div
                                    className="grid grid-cols-10 gap-3 p-5 rounded-2xl bg-white/30 backdrop-blur"
                                    style={{
                                        marginTop: BASE_Y_MARGIN * 2
                                    }}
                                >
                                    {
                                        props.prize?.drawnNumbers?.map((number: number, index: number) => (
                                            <Ball
                                                key={`ball-${index}`}
                                                number={number}
                                            />
                                        ))
                                    }
                                </div>
                        }

                        {
                            hasDrawnNumbers ?
                                <div
                                    style={{
                                        fontFamily: "Tilt Neon",
                                        fontSize: 22,
                                        color: config.colors?.tertiary,
                                        marginTop: BASE_Y_MARGIN * 2
                                    }}
                                >
                                    {
                                        props.prize.winners.map((winner, index) => (
                                            <div
                                                key={"winner-" + index}

                                                className="flex items-center w-full overflow-hidden text-center overflow-ellipsis whitespace-nowrap"
                                            >
                                                <span className="w-1/6 text-left">
                                                    { String(winner.number).padStart(Configuration.settings?.bondDigits || 7, "0") }
                                                </span>

                                                <span className="flex-1 font-bold uppercase truncate text-start">
                                                    { winner.name }
                                                </span>

                                                <span className="text-right">
                                                    {winner.city}/{winner.state}
                                                </span>
                                            </div>
                                        ))
                                    }
                                </div>
                            :
                                <div
                                    className="flex flex-col gap-3 leading-[1.1]"
                                    style={{
                                        fontFamily: "Tilt Neon",
                                        fontSize: 22,
                                        color: config.colors?.primary,
                                        marginTop: BASE_Y_MARGIN * 2
                                    }}
                                >
                                    {
                                        props.prize.winners.map((winner, index) => (
                                            <div
                                                className="flex items-center"
                                            >
                                                <div className="pr-12 italic font-bold text-center text-[40px]" style={{ width: "80px" }}>
                                                    { String(index + 1 + pageIndex * 5) }
                                                </div>

                                                <div className="w-full overflow-hidden overflow-ellipsis whitespace-nowrap">
                                                    <strong className="block">
                                                        { String(winner?.number).padStart(7, "0") }
                                                    </strong>

                                                    <strong className="block w-full overflow-hidden uppercase overflow-ellipsis whitespace-nowrap cursor-text">
                                                        { winner.name }
                                                    </strong>

                                                    <div className="uppercase overflow-hidden h-[1.65rem] whitespace-nowrap overflow-ellipsis">
                                                        <span>
                                                            { winner?.city } / { winner?.state }
                                                        </span>

                                                        {
                                                            winner.reseller?.name &&
                                                                <span className="px-2">
                                                                    -
                                                                    <span className="w-full px-3 mx-2" style={{ backgroundColor: config.colors?.tertiary }}>
                                                                        Rev.:{" "}
                                                                        <span className="cursor-text">
                                                                            { winner.reseller.name }
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                        }
                    </div>
                </div>
            </foreignObject>
        </svg>
    );
};
