import { Box, MenuItem, Modal, Select, Typography } from "@mui/material";
import { Button, ChipField, Datagrid, DateField, DeleteButton, EditButton, FunctionField, NumberField, Pagination, ReferenceArrayField, ReferenceField, ReferenceManyField, Show, SimpleShowLayout, SingleFieldList, TextField, TopToolbar, useNotify, useRefresh, useShowContext } from "react-admin";
import { createActionRequest, createDataProviderRequest } from "../../../../core/Actions";
import { EmptyListBody } from "../../../parts/EmptyListBody";
import { RecordConsumer } from "../../../parts/RecordConsumer";

import { useState } from "react";
import { generateAuthenticatedURL } from "../../../../utils/RequestUtils";
import { BatchesRules } from "../../ManagedBatches";

interface Raffle {
    id: string;
    title: string;
}

/**
 * Generates a list of URLs to a file.
 * @param megaBatchId The mega-batch ID.
 * @param batchId The batch ID.
 * @param fromBatch The number to start from.
 * @param toBatch The number to end in.
 * @param raffleId (optional) The raffle ID.
 */
function generateUrlsToFile(megaBatchId: number, raffleId?: string) {
    const url: URL = new URL(
        generateAuthenticatedURL("/managedPhysicalRanges/" + megaBatchId + "/actions/export"+(raffleId ? "?raffleId="+raffleId : ""))
    );

    window.open(url);
}

const ResetButton = () => {
    const { record } = useShowContext();

    if (!record) {
        return null;
    }

    const notify = useNotify();
    const refresh = useRefresh();

    const clearDistributions = async () => {
        if (window.confirm(`Deseja apagar distribuições do lote ${record.id}? O gerente deverá recadastrar todos os revendedores.`)) {
            await createActionRequest("post", "managedPhysicalRanges", record.id, "reset");

            notify("Distribuições apagadas. Note que a data de expiração NÃO foi alterada.", { type: "success" });
            refresh();
        }
    };

    const renew = async () => {
        if (window.confirm(`Deseja renovar a expiração do lote ${record.id}?`)) {
            await createActionRequest("post", "managedPhysicalRanges", record.id, "renew");

            notify("Data de expiração renovada. Verifique a nova data.", { type: "success" });
            refresh();
        }
    };

    return (
        <>  
            {
                BatchesRules[record.kind].hasExpirationAt && (
                    <>
                        <Button label="Apagar distribuições" onClick={clearDistributions} />
                        <Button label="Renovar expiração" onClick={renew} />
                    </>
                )
            }
        </>
    )
}

const GenerateUrlsButton = () => {
    const { record } = useShowContext();
    const [modalOpen, setModalOpen] = useState(false);
    const [raffles, setRaffles] = useState<Raffle[]>([]);
    const [selectedRaffle, setSelectedRaffle] = useState<Raffle | null>(null);

    const fetchRaffles = async () => {
        try {
            const response = await createDataProviderRequest("get", "raffles", null, {
                query: {
                    filter: {
                        categoryId: record.filters.categories,
                        status: ["DRAFT", "PRIVATE", "OPEN"]
                    },

                    attributes: ["id", "title"]
                }
            }).then((res) => res.json());

            setRaffles(response);
        } catch (error) {
            console.error(error);
        }
    };

    const handleClick = () => {
        if (BatchesRules[record.kind].requiresRaffleId) {
            fetchRaffles();
            setModalOpen(true);
        } else {
            generateUrlsToFile(record.id);
        }
    };

    const handleClose = () => {
        setModalOpen(false);
        setSelectedRaffle(null);
    };

    const handleConfirm = async () => {
        if (!selectedRaffle) {
            return;
        }

        try {
            generateUrlsToFile(record.id, selectedRaffle.id);
            handleClose();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <Button onClick={handleClick} label="Exportar" />

            <Modal
                open={modalOpen}
                onClose={handleClose}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "90%",
                        maxWidth: 600,
                        bgcolor: "background.paper",
                        borderRadius: 2,
                        boxShadow: 24,
                        p: 4
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        Selecionar Sorteio
                    </Typography>

                    <Typography variant="body1" paragraph>
                        Selecione um sorteio da lista abaixo para exportar.
                    </Typography>

                    <Select
                        value={selectedRaffle?.id || "initial"}
                        fullWidth

                        onChange={(e) => {
                            const raffle = raffles.find((raffle) => raffle.id === e.target.value);
                            setSelectedRaffle(raffle || null);
                        }}
                    >
                        <MenuItem value="initial">
                            Selecione um sorteio
                        </MenuItem>

                        {
                            raffles.map((raffle) => (
                                <MenuItem key={raffle.id} value={raffle.id}>
                                    {raffle.title}
                                </MenuItem>
                            ))
                        }
                    </Select>
                    
                    <Box mt={3} display="flex" justifyContent="space-between">
                        <Button onClick={handleClose} label="Cancelar"/>

                        <Button
                            label="Confirmar"
                            onClick={handleConfirm}
                            disabled={!selectedRaffle}
                        />
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

const DateFields = () => {
    const { record } = useShowContext();

    if (!record) {
        return null;
    }

    return (
        <>
            {
                BatchesRules[record.kind].hasExpirationAt && (
                    <div className="flex flex-col gap-2">
                        <div>
                            <Typography className="!text-xs text-gray-500">Expira em</Typography>
                            <DateField source="expiresAt" showTime={true} />
                        </div>

                        <div>
                            <Typography className="!text-xs text-gray-500">Apagado pela última vez em</Typography>
                            <DateField source="lastResetAt" showTime={true} emptyText="Não houve" />
                        </div>
                    </div>
                )
            }
        </>
    );
};

const ManagedBatchActions = () => {
    return (
        <TopToolbar>
            <ResetButton />
            <GenerateUrlsButton />

            <EditButton />
            <DeleteButton />
        </TopToolbar>
    );
}
export const ManagedBatchesShow = () => (
    <Show
        actions={<ManagedBatchActions />}
    >
        <SimpleShowLayout>
            <TextField source="fromBatch" />
            <TextField source="toBatch" />

            <FunctionField
                source="kind"
                render={(val) => {
                    switch (val.kind) {
                        case "FAST_SALE":
                            return "Venda rápida";
                        case "PHYSICAL_SALE":
                            return "Venda física";
                        case "NORMAL_SALE":
                            return "Venda normal";
                    }
                }}
            />

            <TextField source="batchesCount" sortable={false} />
            <NumberField source="bondsPerBatch" />
            <NumberField source="numbersPerBond" />

            <DateFields />

            <FunctionField source="occupancyRatio" sortable={false} render={(val) => (Number(val.occupancyRatio) * 100).toFixed(0) + "%"} />

            <ReferenceArrayField reference="raffleCategories" source="filters.categories" emptyText="Nenhuma">
                <SingleFieldList>
                    <ChipField source="label" />
                </SingleFieldList>
            </ReferenceArrayField>

            <RecordConsumer>
                {(record) =>
                    <div className="my-6">
                        <div className="flex flex-wrap justify-between md:flex-nowrap">
                            <Typography variant="h6">
                                Distribuições deste lote
                            </Typography>
                        </div>

                        <div className="mt-6">
                            <ReferenceManyField sort={{ field: "batchNumber", order: "ASC" }} pagination={<Pagination />} reference="affiliatePhysicalRanges" target="managedRangeId" filter={{ managedRangeId: record.id }}>
                                <Datagrid rowClick={undefined} bulkActionButtons={false} empty={<EmptyListBody label="Ainda não há revendedores associados. O gerente já pode adicionar revendedores." />}>
                                    <TextField source="batchNumber" label="Lote" />

                                    <ReferenceField reference="affiliates" source="affiliateId">
                                        <TextField source="name" />
                                    </ReferenceField>

                                    <ReferenceField reference="affiliates" source="managerId">
                                        <TextField source="name" />
                                    </ReferenceField>
                                </Datagrid>
                            </ReferenceManyField>
                        </div>
                    </div>
                }
            </RecordConsumer>
        </SimpleShowLayout>
    </Show>
);
