import PropTypes from "prop-types";
import { useState } from "react";
import { useLocation } from "react-router-dom";

import LockIcon from "@mui/icons-material/Lock";
import {
    Avatar,
    Button,
    Card,
    CardActions,
    CircularProgress,
} from "@mui/material";
import {
    Form,
    required,
    TextInput,
    useLogin,
    useNotify,
    useTranslate,
} from "react-admin";

import Box from "@mui/material/Box";

const Login = () => {
    const [loading, setLoading] = useState(false);
    const [mfaRequired, setMfaRequired] = useState(false);

    const translate = useTranslate();

    const notify = useNotify();
    const login = useLogin();
    const location = useLocation();

    const handleSubmit = (auth: FormValues) => {
        setLoading(true);
        login(
            auth,
            location.state ? (location.state as any).nextPathname : "/"
        )
            .finally(() => setLoading(false))
            .catch((error: Error & {
                code: string;
            }) => {
                // If it's a "MFA Required" error
                if (error.code === "MFA_REQUIRED") {
                    setMfaRequired(true);
                    return;
                }

                setLoading(false);
                notify(
                    typeof error === "string"
                        ? error
                        : typeof error === "undefined" || !error.message
                        ? "ra.auth.sign_in_error"
                        : error.message,
                    {
                        type: "warning",
                        messageArgs: {
                            _:
                                typeof error === "string"
                                    ? error
                                    : error && error.message
                                    ? error.message
                                    : undefined,
                        },
                    }
                );
            });
    };

    return (
        <Form onSubmit={handleSubmit} noValidate>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "100vh",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                }}
            >
                <Card sx={{ minWidth: 300, marginTop: "6em" }}>
                    <Box
                        sx={{
                            margin: "1em",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Avatar sx={{ bgcolor: "secondary.main" }}>
                            <LockIcon />
                        </Avatar>
                    </Box>

                    <Box sx={{ padding: "0 1em 1em 1em" }}>
                        { !mfaRequired
                            ? <>
                                <Box sx={{ marginTop: "1em" }}>
                                    <TextInput
                                        autoFocus
                                        source="username"
                                        label="Endereço de e-mail"
                                        disabled={loading}
                                        validate={required()}
                                        fullWidth
                                    />
                                </Box>

                                <Box>
                                    <TextInput
                                        source="password"
                                        label="Senha"
                                        type="password"
                                        disabled={loading}
                                        validate={required()}
                                        fullWidth
                                    />
                                </Box>
                            </>
                            : <>
                                <Box sx={{ marginTop: "1em" }}>
                                    <TextInput
                                        source="otp"
                                        label="Código de autenticação"
                                        helperText="Insira o código de autenticação de 2 fatores."
                                        disabled={loading}
                                        validate={required()}
                                        fullWidth
                                        autoComplete="off"
                                        type="number"
                                    />
                                </Box>
                            </>
                        }
                    </Box>

                    <CardActions sx={{ padding: "0 1em 1em 1em" }}>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={loading}
                            fullWidth
                        >
                            {loading && (
                                <CircularProgress size={25} thickness={2} />
                            )}
                            {translate("ra.auth.sign_in")}
                        </Button>
                    </CardActions>
                </Card>
            </Box>
        </Form>
    );
};

Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

export default Login;

interface FormValues {
    username?: string;
    password?: string;
}
