import { AutocompleteArrayInput, CreateButton, Datagrid, DateField, ExportButton, FilterButton, FunctionField, List, NumberField, Pagination, ReferenceArrayInput, ReferenceField, TextField, TopToolbar } from "react-admin";
import { createDataProviderRequest } from "../../../../core/Actions";
import { exportToExcel } from "../../../../core/exportToExcel";

const ListPagination = () => <Pagination perPage={10} rowsPerPageOptions={[5, 10, 30, 50, 100]} />;

const RaffleLuckyNumberFilter = [
    <ReferenceArrayInput alwaysOn source="raffleId" reference="raffles">
        <AutocompleteArrayInput fullWidth optionText="title" label="resources.userLuckyNumbers.filters.raffleTitle" />
    </ReferenceArrayInput>
];

const RaffleResultsActions = () => (
    <TopToolbar>
        <CreateButton />
        <ExportButton maxResults={Number.MAX_SAFE_INTEGER} />
        <FilterButton />
    </TopToolbar>
);

const CHUNK_SIZE = 500;

/**
 * Fetches all pages of a given resource.
 * @param resource The resource name.
 * @param filter Any filter data.
 * @param attributes The attributes to be retrieved.
 * @returns
 */
async function fetchAllPages<T>(resource: string, filter: {
    id: number[];
}, attributes: string[]) {
    const results: T[] = [];

    // Make the pages
    const chunks: number[][] = [];

    for (let i = 0; i < filter.id.length; i += CHUNK_SIZE) {
        chunks.push(
            filter.id.slice(i, i + CHUNK_SIZE)
        )
    }

    for (const chunk of chunks) {
        const response = await createDataProviderRequest("get", resource, null, {
            query: {
                filter: {
                    id: chunk
                },
                attributes
            }
        });

        const data = await response.json();
        results.push(...data);
    }

    return results;
};

const exporter = async (records: {
    numberId: number;
    prizeId: number;
    earnedMoney: number;
}[]) => {
    // Ignore if there are no records to export
    if (!records) {
        return;
    }

    // Helper to make return unique [key]s.
    const uniqueIds = (key: string, target: any[] = records) => (
        target
            .map((r) => r[key])
            .filter((a, i, arr) => arr.indexOf(a) === i)
    );

    // First, fetch all lucky numbers
    const luckyNumbers = await fetchAllPages<{
        id: number;
        userId: number;
        number: number;
    }>("userLuckyNumbers", {
        id: uniqueIds("numberId")
    }, ["id", "userId", "number"]);

    // Then, fetch the prizes
    const prizes = await fetchAllPages<{
        id: number;
        name: string;
    }>("rafflePrizes", {
        id: uniqueIds("prizeId")
    }, ["id", "name"]);

    // Then, fetch the users
    const users = await fetchAllPages<{
        id: number;
        name: string;
        email: string;
        cpf: string;
        tel: string;
    }>("users", {
        id: uniqueIds("userId", luckyNumbers)
    }, ["id", "name", "socialName", "email", "cpf", "tel"]);

    // Now, we need to join all data
    const data = records.map((record) => {
        const luckyNumber = luckyNumbers.find((l) => l.id === record.numberId)!;
        const prize = prizes.find((p) => p.id === record.prizeId)!;
        const user = users.find((u) => u.id === luckyNumber.userId)!;

        return {
            luckyNumber: luckyNumber.number,
            prize: prize.name,
            name: user.name,
            email: user.email,
            cpf: user.cpf,
            tel: user.tel,
            earnedMoney: formatReais(record.earnedMoney)
        };
    });

    exportToExcel(data, "resultados-sorteio");
};

const reaisNumberFormatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
});

const formatReais = (value: number | string) => (["number", "string"].includes(typeof value) && reaisNumberFormatter.format(Number(value))) || "";

export const RaffleResultsList = () => (
    <List
        filters={RaffleLuckyNumberFilter}
        actions={<RaffleResultsActions />}
        pagination={<ListPagination />}
        perPage={10}
        exporter={exporter}
    >
        <Datagrid bulkActionButtons={false} rowClick="show">
            <NumberField source="id" />

            <ReferenceField source="prizeId" reference="rafflePrizes">
                <TextField source="name" />
            </ReferenceField>

            <ReferenceField label="Número sorteado" source="numberId" reference="userLuckyNumbers">
                <TextField source="number" />
            </ReferenceField>

            <FunctionField 
                source="earnedMoney" 
                render={(value) => formatReais(value.earnedMoney)} 
                sortable={false} 
            />

            <ReferenceField label="Nome do ganhador sorteado" source="numberId" reference="userLuckyNumbers">
                <ReferenceField source="userId" reference="users">
                    <TextField source="name" />
                </ReferenceField>
            </ReferenceField>

            <ReferenceField label="Cidade/Estado" source="numberId" reference="userLuckyNumbers">
                <ReferenceField source="userId" reference="users">
                    <FunctionField
                        sortBy="city"
                        render={record => `${record.city} / ${record.state}`}
                    />
                </ReferenceField>
            </ReferenceField>

            <ReferenceField label="Sorteio" source="prizeId" reference="rafflePrizes">
                <ReferenceField source="raffleId" reference="raffles">
                    <TextField source="title" />
                </ReferenceField>
            </ReferenceField>

            <NumberField label="ID do número" source="numberId" />

            <DateField showTime={true} source="createdAt" />
        </Datagrid>
    </List>
);
