import { ArrayInput, NumberInput, SelectInput, SimpleFormIterator } from "ra-ui-materialui";
import { minValue, required } from "react-admin";
import { BrazilianStates } from "../../../../core/BrazilianStates";

const ConfigSection = ({children}) => {
    return <div className="pl-5 ml-5 border-l-[1px] border-primary">
        {children}
    </div>
}

export const RulesFormTab = () => {
    return <div className="grid w-full grid-cols-1 gap-3 xl:grid-cols-2">
        <div>
            <h1 className="mb-5 text-lg">Venda online</h1>

            <ConfigSection>
                <NumberInput
                    defaultValue="1"
                    source="settings.onlineSell.minNumber"
                    fullWidth
                />

                <NumberInput
                    source="settings.onlineSell.maxNumber"
                    validate={[minValue(1)]}
                    fullWidth
                />
            </ConfigSection>
        </div>

        <div>
            <h1 className="mb-5 text-lg">Venda física</h1>

            <ConfigSection>
                <NumberInput
                    source="settings.physicalSell.minNumber"
                    fullWidth
                />

                <NumberInput
                    source="settings.physicalSell.maxNumber"
                    validate={[minValue(1)]}
                    fullWidth
                />
            </ConfigSection>
        </div>

        <div>
            <h1 className="mb-5 text-lg">Regras de regionalização</h1>

            <ConfigSection>
                <ArrayInput
                    source="settings.participationRules.allowedCountryStates"
                    label="Permitir somente estes estados a comprar"
                >
                    <SimpleFormIterator inline={false}>
                        <SelectInput
                            source=""
                            label="Estado"
                            className="w-full"
                            validate={[required()]}
                            choices={BrazilianStates}
                            defaultValue={"SP"}
                        />
                    </SimpleFormIterator>
                </ArrayInput>
            </ConfigSection>
        </div>
    </div>;
};
