import { BooleanInput, ImageField, ImageInput, NumberInput, SimpleForm, TextInput } from "ra-ui-materialui";
import { required } from "react-admin";
import { ColorInput } from "react-admin-color-picker";

export const RaffleCategoriesForm = () => {
    return (
        <SimpleForm>
            <ImageInput
                fullWidth
                accept="image/png, image/jpg, image/jpeg"
                source="iconUrl"
            >
                <ImageField fullWidth source="src" title="title" />
            </ImageInput>

            <ColorInput source="color" picker="Sketch" helperText="Cor utilizada para a exibição em alguns locais." />
            <TextInput fullWidth source="label" validate={required()} helperText="Um título para a categoria." />
            <TextInput fullWidth source="slug" validate={required()} helperText="Um identificador para a categoria, é utilizado principalmente na URL dos resultados." />
            <TextInput fullWidth source="description" validate={required()} />

            <BooleanInput
                fullWidth
                source="data.isMainCategory"
                validate={required()}
                helperText="Considera esta categoria como o produto principal e em destaque vendido no site"
            />

            <BooleanInput
                fullWidth
                source="data.isFeatured"
                helperText="Destaca esta categoria em listagens de categorias do site"
            />

            <BooleanInput fullWidth source="unlisted" validate={required()} />
            <NumberInput fullWidth source="displayOrder" defaultValue={10} helperText="A exibição será feita em ordem crescente (menores valores primeiro)" />
        </SimpleForm>
    );
}
