import { DateTime } from "luxon";
import { createDataProviderRequest } from "../../../core/Actions";
import { removeUndefined } from "../../../utils/ObjectUtils";
import { IntermediateRafflePrize, IntermediateRaffleResult } from "../ResultGeneratorPage";
import { ensureSwitchUnreached } from "../../../utils/RuntimeGuardUtils";

export interface RaffleListItem {
    id: number | string;
    title: string;
}

export enum RaffleKind {
    MAIN = "MAIN",
    SECONDARY = "SECONDARY",
    GAMBLING = "GAMBLING",
    DAILY = "DAILY"
}

export enum PrizeRaffleKind {
    MATRIXES = "MATRIXES",
    LUCKY_NUMBERS = "LUCKY_NUMBERS",
    GAMBLING = "GAMBLING"
}

/** Details about a prize kind. */
const infoPerPrizeKind: Record<PrizeRaffleKind, { hasMatrix: boolean }> = {
    [PrizeRaffleKind.MATRIXES]: { hasMatrix: true },
    [PrizeRaffleKind.GAMBLING]: { hasMatrix: true },
    [PrizeRaffleKind.LUCKY_NUMBERS]: { hasMatrix: false }
};

/** Details about a raffle kind. */
const infoPerRaffleKind: Record<RaffleKind, { source: "normal-raffles" | "daily-raffles", title: string }> = {
    [RaffleKind.MAIN]: {
        title: "Principal",
        source: "normal-raffles"
    },
    [RaffleKind.SECONDARY]: {
        title: "Secundário",
        source: "normal-raffles"
    },
    [RaffleKind.GAMBLING]: {
        title: "Apostas",
        source: "normal-raffles"
    },
    [RaffleKind.DAILY]: {
        title: "Diário",
        source: "daily-raffles"
    }
};

/**
 * Simple accessor to the info of a prize kind.
 */
export function getPrizeKindInfo(prizeKind: string): typeof infoPerPrizeKind[keyof typeof infoPerPrizeKind] {
    return infoPerPrizeKind[prizeKind];
}

/** Simple accessor to the info of a raffle kind. */
export function getRaffleKindInfo(raffleKind: string): typeof infoPerRaffleKind[keyof typeof infoPerRaffleKind] {
    return infoPerRaffleKind[raffleKind];
}

/**
 * Retrieves a given raffle as the intermediate result.
 * @param raffleKind The raffle kind.
 * @param raffleId The raffle ID.
 * @returns
 */
export async function retrieveRaffleAsIntermediateResult(raffleKind: RaffleKind, raffleId: number | string): Promise<IntermediateRaffleResult> {
    const kindInfo = getRaffleKindInfo(raffleKind);

    if (!kindInfo) {
        throw new Error(`Unknown raffle kind "${raffleKind}"`);
    }

    switch (kindInfo.source) {
        case "normal-raffles": {
            return gatherMainRaffleInformation(raffleId as number) as any;
        }

        case "daily-raffles": {
            return gatherDailyRaffleInformation(raffleId as string) as any;
        }

        default: {
            ensureSwitchUnreached(kindInfo.source);
        }
    }
}

/**
 * Gathers information about a single daily raffle.
 * @param raffleId The raffle ID.
 * @returns
 */
async function gatherDailyRaffleInformation(raffleId: string) {
    const response = await createDataProviderRequest("get", "dailyRaffles", null, {
        query: {
            filter: {
                "raffles:_id": raffleId
            }
        }
    }).then((r) => r.json());

    if (!response[0]?.raffles?.length) {
        return null;
    }

    const sortedRaffles = response[0]?.raffles.sort((a, b) => a._id.localeCompare(b._id));

    // Finds both the raffle and the order
    const { raffle, order } = sortedRaffles.reduce((acc, raffle) => {
        // If the raffle is found, just skip
        if (acc.raffle) return acc;

        acc.order++;

        if (raffle._id === raffleId) {
            acc.raffle = raffle;
        }

        return acc;
    }, { raffle: null, order: 0 });

    return {
        id: raffle._id,
        drawDate: response[0].date,
        name: raffle.title,
        prizes: Object.values(raffle.prizes.reduce((c: Record<string, IntermediateRafflePrize>, p) => {
            c[p.title] ??= {
                id: p.title,
                name: p.title,
                raffleKind: PrizeRaffleKind.LUCKY_NUMBERS,
                winners: [],
                order: order,
                time: raffle.time
            } as IntermediateRafflePrize;

            c[p.title].winners.push({
                name: p.user.name,
                number: p.luckyNumber.number,
                city: p.user.city,
                state: p.user.state,
                reseller: p.reseller
            });

            return c;
        }, {} as Record<string, IntermediateRafflePrize>))
    } as IntermediateRaffleResult;
}

/**
 * Gathers information about a single main or secondary raffle.
 * @param raffleId The raffle ID.
 * @returns
 */
async function gatherMainRaffleInformation(raffleId: number) {
    // Retrieve all information in parallel
    const [
        raffle,
        prizes,
        results
    ] = await Promise.all([
        (await createDataProviderRequest("get", `raffles/${raffleId}`)).json(),

        (await createDataProviderRequest("get", "rafflePrizes", null, {
            query: {
                filter: {
                    raffleId
                }
            },

            attributes: ["id", "title", "raffleKind"]
        })).json(),

        (await createDataProviderRequest("get", "raffleResults", null, {
            query: {
                filter: {
                    raffleId
                }
            }
        })).json()
    ]);

    // If there are results
    if (results.length) {
        // Retrieve the lucky numbers
        const ulns = await createDataProviderRequest("get", "userLuckyNumbers", null, {
            query: {
                filter: {
                    id: [...new Set(results.map((r) => r.numberId))]
                },

                attributes: ["id", "number", "userId", "paymentId"]
            }
        })
            .then((r) => r.json());

        // Them, retrieve the users
        const users = await createDataProviderRequest("get", "users", null, {
            query: {
                filter: {
                    id: [...new Set(ulns.map((r) => r.userId))]
                },

                attributes: ["id", "name", "socialName", "city", "state"]
            }
        })
            .then((r) => r.json());

        // Iterate over all lucky numbers
        for (const uln of ulns) {
            // Find the result for it
            const result = results.find((r) => r.numberId === uln.id);

            // Set the user for it
            result.user = users.find((u) => u.id === uln.userId);
            result.number = uln.number;
        }

        // Retrieve the reseller comission
        const resellerCommissions = await createDataProviderRequest("get", "affiliateCommissions", null, {
            query: {
                filter: {
                    paymentId: [...new Set(ulns.map((uln) => uln.paymentId))]
                },

                attributes: ["affiliateId", "paymentId"]
            }
        })
            .then((r) => r.json());

        if (resellerCommissions.length) {
            // Retreieve the resellers
            const affiliates = await createDataProviderRequest("get", "affiliates", null, {
                query: {
                    filter: {
                        id: [...new Set(resellerCommissions.map((r) => r.affiliateId))]
                    }
                }
            })
                .then((r) => r.json());

            // Iterate over all resellers commissions
            for (const commission of resellerCommissions) {
                const possibleAffiliates = affiliates.filter((r) => r.id === commission.affiliateId);

                // Find the reseller for it
                const reseller = (
                    // First try to find a reseller
                    possibleAffiliates.find((r) => r.role === "RESELLER")
                    // If there's no reseller, try to find a coordinator
                    ?? possibleAffiliates.find((r) => r.role === "COORDINATOR")
                );

                const ulnsOfCommission = ulns.filter((uln) => uln.paymentId === commission.paymentId);
                const resultsOfCommission = results.filter((r) => ulnsOfCommission.find((uln) => uln.id === r.numberId));

                // Set it into the result
                for (const result of resultsOfCommission) {
                    result.reseller = reseller;
                }
            }
        }
    }

    await Promise.all(
        prizes
            // Filter only the MATRIXES prizes containing results
            .filter((p) =>
                getPrizeKindInfo(p.raffleKind)?.hasMatrix &&
                results.some((r) => r.prizeId === p.id)
            )

            // Find their drawn numbers
            .map(async (p) => {
                const drawnNumbers = await createDataProviderRequest("get", "raffleMatrixDraws", null, {
                    query: {
                        filter: {
                            prizeId: p.id
                        },

                        attributes: ["number"]
                    }
                })
                    .then((r) => r.json());

                // Map to the drawn numbers
                p.drawnNumbers = drawnNumbers.map((d) => d.number);
            })
    );

    return {
        id: raffle.id,
        name: raffle.name,
        drawDate: raffle.drawDate,

        prizes: prizes.map((p) => ({
            id: p.id,
            name: p.name,
            raffleKind: p.raffleKind,
            drawnNumbers: p.drawnNumbers ?? [],

            winners: results
                .filter((r) => r.prizeId === p.id)
                .map((r) => ({
                    name: r.user.name,
                    number: r.number,
                    photoUrl: r.winnerPhotoUrl,
                    city: r.user.city,
                    state: r.user.state,
                    reseller: r.reseller
                }))
        }))
            .filter((p) => p.winners.length > 0)
    } as IntermediateRaffleResult;
}

/**
 * Retrieves a list of available raffles.
 * @param filters All filters to be applied to the raffles.
 * @returns
 */
export async function getRaffleList(filters: {
    raffleKind: RaffleKind;
    date?: string | null | undefined;
}): Promise<RaffleListItem[] | undefined> {
    const { raffleKind } = filters;

    const parsedDate: DateTime | undefined = (
        filters.date
            ? DateTime.fromISO(filters.date).setZone("UTC-3")
            : undefined
    );

    if (!parsedDate?.isValid) {
        return [];
    }

    const kindInfo = getRaffleKindInfo(raffleKind);

    if (!kindInfo) {
        throw new Error(`Unknown raffle kind "${raffleKind}"`);
    }

    // If it's a MAIN or SECONDARY raffle kind
    if (kindInfo.source === "normal-raffles") {
        // Retrieve the raffles
        const raffles = await createDataProviderRequest<{
            id: number;
            title: string;
        }[]>("get", "raffles", null, {
            query: {
                filter: removeUndefined({
                    kind: raffleKind,
                    drawDate: parsedDate?.isValid ? {
                        $gte: parsedDate.startOf("day").toISO(),
                        $lte: parsedDate.endOf("day").toISO()
                    } : undefined
                })
            }
        })
            .then((r) => r.json());

        return raffles;
    } else
    // If it's a daily raffle
    if (kindInfo.source === "daily-raffles") {
        const data = await createDataProviderRequest<{
            id: string;

            raffles: {
                _id: string;
                title: string;
                time: string;
            }[];
        }[]>("get", "dailyRaffles", null, {
            query: {
                filter: parsedDate?.isValid ? {
                    date$gte: parsedDate.startOf("day").toISO(),
                    date$lte: parsedDate.endOf("day").toISO(),
                } : undefined
            }
        })
            .then((r) => r.json());

        return data.flatMap((d) => (
            d.raffles.map((r) => ({
                id: r._id,
                title: `${r.title} - ${r.time}`
            }))
        ));
    }
}
